import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { css } from 'glamor';
import { white, grey, cyan, lighten, rgba } from '../../styles/colors';
import { regular } from '../../styles/fonts';
import { content } from '../../styles/spacing';
import { focus, transition, borderRadius } from '../../styles/shared';
import CustomPropTypes from '../../utils/customPropTypes';
import { lt } from '../../styles/mediaQueries';
import Spinner from '../Spinner';

const dimensions = {
  width: '200px',
  height: '74px',
};

const backgroundColorByRank = {
  primary: cyan.primary,
  secondary: grey.dark,
  outline: 'transparent',
};

const getStyles = ({ disabled, loading, rank, ...rest }) => {
  const backgroundColor = backgroundColorByRank[rank];
  const baseStyles = {
    ...dimensions,
    border: '1px solid',
    borderColor: rank === 'outline' ? white.primary : 'transparent',
    borderRadius,
    display: 'inline-block',
    textAlign: 'center',
    textDecoration: 'none',
    padding: '18px 0',
    ...regular,
    ...content,
    marginBottom: 0,
    color: white.light,
    backgroundColor,
    transition: transition('background-color', 'box-shadow', 'color'),
    ...rest,

    [lt.sm]: {
      width: '150px',
      height: '64px',
      paddingTop: '12px',
      paddingBottom: '12px',
    },
  };

  return {
    ...baseStyles,
    ...(disabled
      ? {
          opacity: 0.4,
          color: `${grey.dark}`,
          backgroundColor: rgba(grey.dark, 0.25),
          outline: '0 !important',
        }
      : {
          cursor: 'pointer',
          '&:hover': {
            backgroundColor:
              rank === 'outline'
                ? rgba(white.primary, 0.05)
                : lighten(backgroundColor, 5),
          },
          '&:active': {
            backgroundColor:
              rank === 'outline' ? 'transparent' : backgroundColor,
          },
          ...focus(),
        }),
    ...(loading
      ? {
          color: 'transparent',
          // Use important to account for hover state, etc.
          backgroundColor: `${lighten(backgroundColor, 25)} !important`,
        }
      : {}),
  };
};

const getClassName = props => css(getStyles(props));

// TODO: This is showing as a noisy error. We should probably support
// margins via global props but for now I'm suppressing it by not
// passing it along to the DOM.
const filterProps = props =>
  _.pickBy(props, (val, key) => !['marginLeft'].includes(key));

class Button extends React.Component {
  handleClick = event => {
    const { disabled, onClick } = this.props;
    if (!disabled) {
      onClick(event);
    }
  };

  render() {
    const {
      children,
      disabled,
      onClick,
      loading,
      rank,
      tagName: TagName,
      ...rest
    } = this.props;
    return (
      <TagName
        type="button"
        disabled={disabled || loading}
        onClick={this.handleClick}
        className={getClassName({
          disabled,
          rank,
          loading,
          ...rest,
        })}
        {...filterProps(rest)}
      >
        {loading ? <Spinner /> : children}
      </TagName>
    );
  }
}

Button.propTypes = {
  rank: PropTypes.oneOf(['primary', 'secondary']),
  tagName: PropTypes.oneOf(['button', 'a']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  children: CustomPropTypes.children.isRequired,
};

Button.defaultProps = {
  rank: 'primary',
  tagName: 'button',
  disabled: false,
  loading: false,
  onClick: () => {
    // noop
  },
};

export default Button;
