import PropTypes from 'prop-types';

const glamorClassName = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object,
]);

const children = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]);

const navEntries = PropTypes.arrayOf(
  PropTypes.shape({
    title: PropTypes.string.isRequired,
    to: PropTypes.string,
    href: PropTypes.string,
  })
);

const CustomPropTypes = {
  glamorClassName,
  children,
  navEntries,
};

export default CustomPropTypes;
