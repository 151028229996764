import * as breakpoints from './breakpoints';
import { queryGte, queryLt, queryBetween } from './utils/queryBreakpoint';

export const gte = {
  sm: queryGte(breakpoints.sm),
  md: queryGte(breakpoints.md),
  lg: queryGte(breakpoints.lg),
  xl: queryGte(breakpoints.xl),
};

export const lt = {
  sm: queryLt(breakpoints.sm),
  md: queryLt(breakpoints.md),
  lg: queryLt(breakpoints.lg),
  xl: queryLt(breakpoints.xl),
};

export const btwn = {
  sm: {
    md: queryBetween(breakpoints.sm, breakpoints.md),
    lg: queryBetween(breakpoints.sm, breakpoints.lg),
    xl: queryBetween(breakpoints.sm, breakpoints.xl),
  },
  md: {
    lg: queryBetween(breakpoints.md, breakpoints.lg),
    xl: queryBetween(breakpoints.md, breakpoints.xl),
  },
  lg: {
    xl: queryBetween(breakpoints.lg, breakpoints.xl),
  },
};
