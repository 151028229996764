import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Base64 } from 'js-base64';
import { setCookie, getCookie } from '../../utils/cookie';
import { getNetlifyApiUrl } from '../../utils/netlify';
import { withRestApis, RestApiPropType } from '../RestApiManager';

const cookieName = 'amanyScottyAccessCode';

const createGetHasPropsBecomeTruthy = (prevProps, currentProps) => keyPath =>
  !_.get(prevProps, keyPath) && _.get(currentProps, keyPath);

const storePassword = password => {
  setCookie({
    name: cookieName,
    value: Base64.encode(password),
    daysToExpire: 7,
  });
};

const apis = {
  getDetails: {
    url: getNetlifyApiUrl('/details'),
    method: 'POST',
  },
};

class DetailsManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      details: false,
      accessGranted: false,
    };
  }

  componentDidMount() {
    const accessCode = getCookie({ name: cookieName });
    const { getDetails } = this.props;
    if (accessCode) {
      getDetails.execute({ password: Base64.decode(accessCode) });
    }
  }

  componentDidUpdate(prevProps) {
    const getHasPropsBecomeTruthy = createGetHasPropsBecomeTruthy(
      prevProps,
      this.props
    );
    if (getHasPropsBecomeTruthy(['getDetails', 'success'])) {
      const self = this;
      window.setTimeout(() => {
        self.setState({ accessGranted: true });
      }, 2000);
    }
  }

  handleRequestDetails = async password => {
    const { getDetails } = this.props;
    this.password = password;
    await getDetails.execute({ password });
    storePassword(password);
  };

  render() {
    const { children, getDetails } = this.props;
    const details = _.get(getDetails, ['success', 'payload'], {});
    const { handleRequestDetails } = this;

    return React.createElement(children, {
      ...this.state,
      getDetails,
      details,
      handleRequestDetails,
    });
  }
}

DetailsManager.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.func,
  ]).isRequired,
  getDetails: RestApiPropType.isRequired,
};

export default withRestApis(apis)(DetailsManager);
