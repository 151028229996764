import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import querystring from 'querystring';
import _ from 'lodash';

const initialRequestState = {
  pending: false,
  success: null,
  failure: null,
};

const getRequestArgs = (apiConfig, body) => {
  const { method, url } = apiConfig;
  switch (method) {
    case 'GET': {
      return [[url, querystring.stringify(body)].join('?')];
    }
    case 'POST': {
      return [url, body];
    }
    default: {
      throw Error(`Unsupported method - "${method}"`);
    }
  }
};

class RestApiManager extends React.Component {
  constructor(props) {
    super(props);

    const { apis } = props;
    this.state = _.reduce(
      apis,
      (acc, val, key) => ({
        ...acc,
        [key]: { ...initialRequestState },
      }),
      {}
    );
  }

  getPropsToPass() {
    return _.reduce(
      this.state,
      (acc, val, key) => ({
        ...acc,
        [key]: {
          // eslint-disable-next-line react/destructuring-assignment
          ...this.state[key],
          execute: this.createExecuteRequest(key),
          destroy: this.createDestroyRequest(key),
        },
      }),
      {}
    );
  }

  initiateRequest = async (key, body) => {
    const { apis } = this.props;
    const apiConfig = apis[key];
    // TODO: verify apiConfig exists
    const { method } = apiConfig;
    const requestArgs = getRequestArgs(apiConfig, body);
    try {
      const { data: payload } = await axios[method.toLowerCase()](
        ...requestArgs
      );
      this.setState({
        [key]: {
          ...initialRequestState,
          success: { payload },
        },
      });
    } catch ({ response: { data } }) {
      this.setState({
        [key]: {
          ...initialRequestState,
          failure: data,
        },
      });
    }
  };

  executeRequest = (key, body) => {
    this.setState(
      {
        [key]: { ...initialRequestState, pending: true },
      },
      () => {
        this.initiateRequest(key, body);
      }
    );
  };

  destroyRequest = key => {
    this.setState({
      [key]: { ...initialRequestState },
    });
  };

  createExecuteRequest(key) {
    return body => this.executeRequest(key, body);
  }

  createDestroyRequest(key) {
    return () => this.destroyRequest(key);
  }

  render() {
    const { render } = this.props;
    return render(this.getPropsToPass());
  }
}

RestApiManager.propTypes = {
  render: PropTypes.func.isRequired,
  // TODO: THis prop has dynamic keys and is going to be a pain
  // to type for now.
  // eslint-disable-next-line react/forbid-prop-types
  apis: PropTypes.object.isRequired,
};

export const withRestApis = apis => WrappedComponent => props => (
  <RestApiManager
    apis={apis}
    render={apiProps => <WrappedComponent {...props} {...apiProps} />}
  />
);

export const RestApiPropType = PropTypes.shape({
  pending: PropTypes.bool,
  success: PropTypes.shape({ payload: PropTypes.object }),
  failure: PropTypes.shape({ error: PropTypes.object }),
});

export default RestApiManager;
