import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Box from '../../components/Box';
import Button from '../../components/Button';
import PasswordInput from '../../components/PasswordInput';
import Spinner from '../../components/Spinner';
import Text from '../../components/Text';
import Carousel from '../../components/Carousel';
import { RestApiPropType } from '../../containers/RestApiManager';
import { passwordRegex } from '../../utils/regex';

const getIsValidPassword = value => passwordRegex.test(value);
const timerDuration = 2000;

const LoadingRegion = () => (
  <Box centerContents height="100%" width="100%">
    <Spinner />
  </Box>
);

const SuccessRegion = () => (
  <Box centerContents resetChildMargins height="100%" width="100%">
    <Text light tagName="h3">
      Welcome!
    </Text>
  </Box>
);

// Trims a trailing space because there's a lot of folks having problems.
const transformPassword = password => password.replace(/ $/, '');

const carouselIndices = {
  formRegion: 0,
  loadingRegion: 1,
  successRegion: 2,
};

class PasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requestTimerActive: false,
      value: '',
    };
  }

  getShowLoading() {
    const { getDetails } = this.props;
    const { requestTimerActive } = this.state;
    return getDetails.pending || requestTimerActive;
  }

  getCarouselIndex() {
    const { getDetails } = this.props;
    if (this.getShowLoading()) {
      return carouselIndices.loadingRegion;
    }
    if (getDetails.success) {
      return carouselIndices.successRegion;
    }

    return carouselIndices.formRegion;
  }

  handleChange = event => {
    this.setState({ value: _.toLower(event.target.value) });
  };

  handleSubmit = event => {
    const { handleRequestDetails } = this.props;
    const { value: password } = this.state;
    const self = this;
    event.preventDefault();
    this.setState({ requestTimerActive: true }, async () => {
      window.setTimeout(() => {
        self.setState({ requestTimerActive: false });
      }, timerDuration);
      handleRequestDetails(transformPassword(password));
    });
  };

  handleResetClick = () => {
    const { getDetails } = this.props;
    this.setState({ value: '' }, getDetails.destroy);
  };

  renderFormRegion() {
    const { value } = this.state;
    return (
      <Box height="100%" tagName="div" resetChildMargins textAlign="center">
        <form onSubmit={this.handleSubmit}>
          <Text light tagName="h4" textAlign="center">
            Password, please.
          </Text>
          <PasswordInput
            light
            onChange={this.handleChange}
            value={value}
            autoComplete="current-password"
            type="password"
            placeholder="Thank you"
            textAlign="center"
          />
          <Button
            type="submit"
            onClick={this.handleSubmit}
            disabled={!getIsValidPassword(value)}
          >
            Submit
          </Button>
        </form>
      </Box>
    );
  }

  renderRetryRegion() {
    return (
      <Box
        height="100%"
        tagName="div"
        resetChildMargins
        centerContents
        flexDirection="column"
        textAlign="center"
      >
        <Text tagName="h3">Not quite</Text>
        <Text>You entered an incorrect password.</Text>
        <Button type="button" onClick={this.handleResetClick}>
          Try again
        </Button>
      </Box>
    );
  }

  renderInteractiveRegion() {
    const { getDetails } = this.props;
    const { failure, pending } = getDetails;
    return failure && !pending
      ? this.renderRetryRegion()
      : this.renderFormRegion();
  }

  render() {
    return (
      <Box
        textAlign="center"
        tagName="div"
        resetChildMargins
        width="350px"
        height="330px"
        fullBleed
      >
        <Carousel index={this.getCarouselIndex()}>
          {this.renderInteractiveRegion()}
          <LoadingRegion />
          <SuccessRegion />
        </Carousel>
      </Box>
    );
  }
}

PasswordForm.propTypes = {
  getDetails: RestApiPropType.isRequired,
  handleRequestDetails: PropTypes.func.isRequired,
};

export default PasswordForm;
