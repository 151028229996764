import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';

import SmoothCollapse from 'react-smooth-collapse';
import { heading, regular } from '../../styles/fonts';
import * as spacing from '../../styles/spacing';
import createGetStylesWithGlobalProps from '../../styles/utils/createGetStylesWithGlobalProps';
import { grey, white, black, cyan, darken } from '../../styles/colors';

const getShouldInherit = tagName => tagName === 'span';
const getIsHeading = tagName =>
  ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].indexOf(tagName) > -1;

const anchorStyles = {
  color: cyan.primary,
  '&:hover': {
    color: darken(cyan.primary, 10),
  },
  '&:active': {
    color: darken(cyan.primary, 20),
  },
  '&:visited': {
    color: grey.dark,
  },
};

const accentStyles = {
  color: grey.primary,
  letterSpacing: '8px',
  textTransform: 'uppercase',
};

const getColor = ({ color, light }) => {
  if (color) {
    return color;
  }
  return light ? white.primary : black.primary;
};

const getStylesBase = ({
  tagName,
  bold,
  accent,
  light,
  italic,
  small,
  color,
  ...rest
}) => ({
  ...(getShouldInherit(tagName)
    ? {}
    : {
        ...(spacing[tagName] || spacing.content),
        ...(getIsHeading(tagName) ? heading : regular),
        color: getColor({ color, light }),
        ...(small ? { fontSize: '16px' } : {}),
        ...(bold ? { fontWeight: 700 } : {}),
        ...(accent ? accentStyles : {}),
        '& a': light ? { color: `${white.primary} !important` } : anchorStyles,
      }),

  fontStyle: italic ? 'italic' : undefined,
  ...rest,
});

const getStyles = createGetStylesWithGlobalProps(getStylesBase);

const Text = ({ children, ...rest }) =>
  React.createElement(
    rest.tagName,
    {
      className: css(getStyles(rest)),
    },
    children
  );

Text.defaultProps = {
  tagName: 'p',
  accent: undefined,
  bold: false,
  light: false,
  italic: false,
};

Text.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  tagName: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'span',
    'label',
  ]),
  bold: PropTypes.bool,
  accent: PropTypes.bool,
  light: PropTypes.bool,
  italic: PropTypes.bool,
};

export default Text;
