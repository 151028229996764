import React from 'react';
import Input from '../Input';
import Text from '../Text';

class PasswordInput extends React.Component {
  state = {
    mask: false,
  };

  handleToggleClick = event => {
    event.preventDefault();
    this.setState(currState => ({ mask: !currState.mask }));
  };

  render() {
    const { mask } = this.state;
    /* eslint-disable jsx-a11y/anchor-is-valid */
    return (
      <>
        <Input {...this.props} type={mask ? 'password' : 'text'} />
        <Text small light>
          <a href="#" onClick={this.handleToggleClick}>
            {mask ? 'Show' : 'Hide'} password
          </a>
        </Text>
      </>
    );
  }
}

export default PasswordInput;
