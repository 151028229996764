import tinyColor from 'tinycolor2';

export const white = {
  light: '#FFFFFF',
  primary: '#FAFAFA',
};

export const grey = {
  light: '#F2F2F2',
  primary: '#BBBBBB',
  dark: '#9C9C9C',
};

export const black = {
  primary: '#333',
};

export const rust = {
  primary: '#e5421f',
};

export const cyan = {
  primary: '#238cb5',
};

export const green = {
  primary: '#14b900',
};

const createTinyColorWrapper = methodName => (color, arg) =>
  tinyColor(color)
    [methodName](arg)
    .toString();

export const rgba = createTinyColorWrapper('setAlpha');
export const lighten = createTinyColorWrapper('lighten');
export const darken = createTinyColorWrapper('darken');
