import { rgba, cyan, black } from './colors';

export const shadow = (color = black.primary) => ({
  boxShadow: `0px 0px 5px 2px ${rgba(color, 0.5)}`,
});

export const transition = (...properties) =>
  properties.map(name => `${name} 0.2s linear`).join(', ');

export const focus = (overrides = {}) => ({
  '&:focus': {
    outline: 0,
    borderColor: cyan.primary,
    ...shadow(cyan.primary),
    ...overrides,
  },
});

const identity = entry => Boolean(entry);
export const combineClassNames = (...classNames) =>
  classNames.filter(identity).join(' ');

export const border = color => `2px solid ${color}`;
export const borderRadius = '4px';

export const pageRowPadding = {
  lg: {
    x: '48px',
    y: '144px',
  },
  sm: {
    x: '24px',
    y: '96px',
  },
};

export const altPageRowPadding = {
  lg: {
    y: '72px',
  },
  sm: {
    y: '54px',
  },
};

export const maxContentWidth = '600px';
