import React from 'react';
import './ball-beat.css';
import { css } from 'glamor';
import { white } from '../../styles/colors';

css.global('.la-ball-beat', {
  color: `${white.primary} !important`,
  margin: '0 auto',
});

const Spinner = () => (
  <div className="la-ball-beat">
    <div />
    <div />
    <div />
  </div>
);

export default Spinner;
