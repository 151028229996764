import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';
import { content } from '../../styles/spacing';

import { spacer } from '../../styles/spacer';
import { regular } from '../../styles/fonts';
import { transition, focus, border, borderRadius } from '../../styles/shared';
import { white, rgba, grey } from '../../styles/colors';

const getClassName = ({ textAlign, light, small }) => {
  const color = light ? white.primary : grey.dark;
  return css({
    padding: spacer(1),
    color,
    border: border(color),
    width: '100%',
    textAlign,
    ...regular,
    ...content,
    ...(small ? { fontSize: '16px' } : {}),
    borderRadius,
    backgroundColor: 'transparent',
    transition: transition('box-shadow', 'border-color'),
    ...focus(),
    '&::placeholder': {
      color: rgba(color, 0.5),
    },
  });
};

const Input = ({ type, textAlign, small, light, long, ...rest }) =>
  React.createElement(long ? 'textarea' : 'input', {
    className: getClassName({ textAlign, light, small, ...rest }),
    type,
    ...rest,
  });

Input.defaultProps = {
  type: 'text',
  textAlign: 'left',
  autoComplete: null,
  small: false,
  light: false,
  long: false,
};

Input.propTypes = {
  type: PropTypes.oneOf(['text', 'password', 'email', 'number', 'tel']),
  textAlign: PropTypes.oneOf(['left', 'right', 'center']),
  autoComplete: PropTypes.oneOf([
    'current-password',
    'username',
    'new-password',
    null,
  ]),
  small: PropTypes.bool,
  light: PropTypes.bool,
  long: PropTypes.bool,
};

export default Input;
