import 'reset-css';
import { css } from 'glamor';

css.global('html', {
  boxSizing: 'border-box',
});

css.global('*, *:before, *:after', {
  boxSizing: 'inherit',
});
