import Shevy from 'shevyjs';
import { lt } from './mediaQueries';

const shevy = new Shevy({
  baseFontSize: '20px',
  baseFontScale: 'perfectFourth',
});

const headingTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

const { h1, h2, h3, h4, h5, h6 } = headingTags.reduce((acc, tagName, index) => {
  const smallerTagName = headingTags[index + 1] || headingTags[index];
  return {
    ...acc,
    [tagName]: {
      ...shevy[tagName],
      [lt.md]: shevy[smallerTagName],
    },
  };
}, {});

const { content, baseSpacing: bs } = shevy;

// If we wish for text to inherit, do not override stylesj.
const span = {};

const small = {
  ...content,
  fontSize: '16px',
};

export { shevy, h1, h2, h3, h4, h5, h6, bs, content, span, small };
