import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';

import { spacer } from '../../styles/spacer';
import { combineClassNames, shadow as shadowStyles } from '../../styles/shared';
import CustomPropTypes from '../../utils/customPropTypes';
import filterPossibleNullProps from '../../utils/filterPossibleNullTypes';

import createGetStylesWithGlobalProps from '../../styles/utils/createGetStylesWithGlobalProps';

const getStylesBase = ({
  minHeight,
  height,
  width,
  position,
  textAlign,
  zIndex,
  top,
  bottom,
  left,
  right,
  shadow,
  flexDirection,
  sizeToViewport,
  centerContents,
  backgroundColor,
  resetChildMargins,
  fullBleed,
  ...rest
}) => ({
  height: height || (sizeToViewport ? '100vh' : 'auto'),
  width: width || (sizeToViewport ? '100%' : 'auto'),
  minHeight,
  backgroundColor,
  textAlign,
  position,
  padding: fullBleed ? 0 : spacer(3),
  ...(shadow ? shadowStyles() : {}),

  ...filterPossibleNullProps({
    top,
    bottom,
    left,
    right,
    zIndex,
  }),

  ...(centerContents
    ? {
        display: 'flex',
        flexAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection,
      }
    : {}),
  ...(resetChildMargins
    ? {
        '> *:first-child': {
          marginTop: '0 !important',
        },
        '> *:last-child': {
          marginBottom: '0 !important',
        },
      }
    : {}),
  ...rest,
});

const getStyles = createGetStylesWithGlobalProps(getStylesBase);

const Box = ({ tagName = 'div', className: _className, children, ...rest }) =>
  React.createElement(
    tagName,
    {
      className: combineClassNames(css(getStyles(rest)), _className),
    },
    children
  );

Box.defaultProps = {
  children: null,
  className: '',
  backgroundColor: 'transparent',
  flexDirection: undefined,
  textAlign: 'left',
  tagName: 'div',
  position: 'static',
  top: null,
  left: null,
  right: null,
  bottom: null,
  zIndex: null,
  shadow: false,
  sizeToViewport: false,
  centerContents: false,
  resetChildMargins: false,
  fullBleed: false,
};

Box.propTypes = {
  className: CustomPropTypes.glamorClassName,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  backgroundColor: PropTypes.string,
  flexDirection: PropTypes.oneOf([undefined, 'row', 'column']),
  position: PropTypes.oneOf(['static', 'relative', 'absolute', 'fixed']),
  textAlign: PropTypes.oneOf(['left', 'right', 'center']),
  top: PropTypes.number,
  left: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number,
  zIndex: PropTypes.number,
  tagName: PropTypes.string,
  shadow: PropTypes.bool,
  sizeToViewport: PropTypes.bool,
  centerContents: PropTypes.bool,
  resetChildMargins: PropTypes.bool,
  fullBleed: PropTypes.bool,
};

export default Box;
