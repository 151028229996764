import _ from 'lodash';

const createGetStylesResponsive = ({
  defaultResponsiveProps,
} = {}) => getStylesFromProps => ({ responsiveProps, ...rest }) =>
  _.reduce(
    _.merge({}, defaultResponsiveProps, responsiveProps) || {},
    (styles, responsiveOverrides, mediaQuery) => ({
      ...styles,
      [mediaQuery]: getStylesFromProps({
        ...rest,
        ...responsiveOverrides,
      }),
    }),
    getStylesFromProps(rest)
  );

export default createGetStylesResponsive;
