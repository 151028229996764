import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Animated } from 'react-animated-css';
import { StaticQuery, graphql } from 'gatsby';

import DetailsManager from '../../containers/DetailsManager';
import Box from '../Box';
import PasswordForm from '../../compositions/PasswordForm';

import withMountGuard from '../../utils/withMountGuard';

import '../../styles/reset';
import 'animate.css';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Fragment>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <DetailsManager>
          {({ getDetails, accessGranted, details, handleRequestDetails }) => (
            <div>
              {React.createElement(children, { accessGranted, details })}
              <Animated
                animationIn="fadeIn"
                animationOut="fadeOut"
                isVisible={!accessGranted}
              >
                <Box
                  centerContents
                  height="100%"
                  width="100%"
                  position="fixed"
                  top={0}
                  left={0}
                  zIndex={10}
                >
                  <PasswordForm
                    {...{
                      getDetails,
                      handleRequestDetails,
                    }}
                  />
                </Box>
              </Animated>
            </div>
          )}
        </DetailsManager>
      </Fragment>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export default withMountGuard(Layout);
