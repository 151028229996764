const baseFontStack = 'Arial,"Helvetica Neue",Helvetica,sans-serif';
const createFontStack = prefix => `"${prefix}", ${baseFontStack}`;

export const fonts = {
  heading: createFontStack('Alegreya Sans'),
  regular: createFontStack('Lato'),
};

export const heading = {
  fontFamily: fonts.heading,
  fontWeight: 900,
};

export const regular = {
  fontFamily: fonts.regular,
  fontWeight: 400,
};
