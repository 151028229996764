import React from 'react';

const withMountGuard = WrappedComponent => {
  class MountGuardManager extends React.Component {
    constructor(props) {
      super(props);
      this.state = { mounted: false };
    }

    componentDidMount() {
      this.setState({ mounted: true });
    }

    render() {
      const { mounted } = this.state;
      return mounted ? <WrappedComponent {...this.props} /> : <div />;
    }
  }
  return MountGuardManager;
};

export default withMountGuard;
